import React from 'react'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import { formatHeight } from '../../../lib/utils/formatters'
import PlayerHeadshotAvatar from './PlayerHeadshotAvatar'
import { formatNumber } from '@/lib/utils/math'

type PositionalPlayerCardProps = {
    player: DTO.BoardPlayer
    isRankedBoard: boolean
    isChildBoard: boolean
}

const PositionalPlayerCard = ({ player: p, isRankedBoard, isChildBoard }: PositionalPlayerCardProps): JSX.Element => (
    <Card sx={{ '@media print': { boxShadow: 0 } }}>
        <CardHeader
            sx={{ '.MuiCardHeader-content': { textAlign: 'left' }, '@media print': { padding: 1 } }}
            avatar={<PlayerHeadshotAvatar imageUrl={p.player.imageUrl} />}
            title={
                <>
                    <b>{p.name}</b> {isRankedBoard && (!isChildBoard || p.isRanked) ? `(${p.rank})` : ''}
                </>
            }
            subheader={
                <>
                    {!!p.player.height && formatHeight(p.player.height)}
                    {!!(p.player.height && p.player.age) && ' | '}
                    {!!p.player.age && `${formatNumber(p.player.age) || ''}yrs`}
                </>
            }
        />
    </Card>
)

export default PositionalPlayerCard
