import React, { useState, useEffect } from 'react'
import union from 'lodash.union'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { positions as allPositions } from '../../../shared/utils/positions'
import PositionalPlayerCard from './PositionalPlayerCard'

type PositionalBoardProps = {
    items: DTO.BoardData['items']
    isRanked: boolean
    isChildBoard: boolean
}

export const defaultPositions = allPositions
const positionTierHeight = '42px'
const PositionalBoard = ({ items, isRanked, isChildBoard }: PositionalBoardProps): JSX.Element => {
    const [positions, setPositions] = useState<string[]>(defaultPositions)

    useEffect(() => {
        const players = items.filter((p) => p.type === 'player') as DTO.BoardPlayer[]
        const posUnion = union(
            defaultPositions,
            players.map((p) => p.player.playerPosition)
        )
        setPositions(posUnion)
    }, [items])

    const tierPositions = items.reduce(
        (
            acc: {
                label: string | undefined
                position: number
                maxPlayersInPosition: number
                players: Record<string, DTO.BoardPlayer[] | undefined>
            }[],
            x
        ) => {
            if (x.type === 'team') return acc
            if (x.type === 'tier') {
                acc.push({ label: x.name, position: x.position, maxPlayersInPosition: 0, players: {} })
            } else {
                // insert "blank" tier for boards where there is no tier in the 1st position
                if (acc.length === 0) acc.push({ label: '', position: 0, maxPlayersInPosition: 0, players: {} })

                const tier = acc[acc.length - 1]
                const { playerPosition: position } = x.player
                if (!(position in tier.players)) tier.players[position] = []

                const positionPlayers = tier.players[position] as DTO.BoardPlayer[]
                positionPlayers.push(x)
                if (positionPlayers.length > tier.maxPlayersInPosition)
                    tier.maxPlayersInPosition = positionPlayers.length
            }
            return acc
        },
        []
    )

    return (
        <Box sx={{ overflowX: 'auto' }}>
            <Box component="table" cellSpacing={0} sx={{ width: '100%', tableLayout: 'fixed' }}>
                <thead>
                    <tr>
                        {positions.map((p, idx) => (
                            <React.Fragment key={p}>
                                {idx > 0 && <Box component="td" sx={({ spacing }) => ({ width: spacing(1) })} />}
                                <Box
                                    component="th"
                                    sx={(theme) => ({
                                        fontWeight: 'normal',
                                        backgroundColor: 'grey.600',
                                        color: 'white',
                                        borderTopLeftRadius: theme.shape.borderRadius,
                                        borderTopRightRadius: theme.shape.borderRadius,
                                        height: positionTierHeight,
                                        width: theme.breakpoints.values.lg / 5 - 6,
                                        '@media print': { width: '100%' },
                                    })}
                                >
                                    {p}
                                </Box>
                            </React.Fragment>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tierPositions.map((t) => (
                        <React.Fragment key={t.position}>
                            {!!t.label && (
                                <tr>
                                    <Box
                                        component="td"
                                        colSpan={positions.length * 2 - 1}
                                        sx={{ position: 'relative', height: positionTierHeight }}
                                    >
                                        <Paper
                                            sx={{
                                                backgroundColor: '#d8d8d8',
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: positionTierHeight,
                                                display: 'flex',
                                                '@media print': { boxShadow: 0 },
                                            }}
                                            elevation={2}
                                        >
                                            <Typography
                                                variant="subtitle2"
                                                sx={{
                                                    position: 'sticky',
                                                    lineHeight: positionTierHeight,
                                                    width: 300,
                                                    textAlign: 'center',
                                                    left: 'calc(50% - 150px)',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                {t.label}
                                            </Typography>
                                        </Paper>
                                    </Box>
                                </tr>
                            )}
                            {new Array(t.maxPlayersInPosition).fill(undefined).map((_, tierRowNum) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <tr key={`${t.position}-${tierRowNum}`}>
                                    {positions.map((p, idx) => {
                                        const pl = t.players[p]?.[tierRowNum]
                                        return (
                                            <React.Fragment key={p}>
                                                {idx > 0 && <td />}
                                                <Box
                                                    component="td"
                                                    sx={{
                                                        paddingX: 1,
                                                        paddingTop: tierRowNum === 0 ? 1 : 0.5,
                                                        paddingBottom:
                                                            tierRowNum === t.maxPlayersInPosition - 1 ? 1 : 0.5,
                                                        verticalAlign: 'top',
                                                        backgroundColor: 'grey.200',
                                                        '@media print': { backgroundColor: 'transparent' },
                                                    }}
                                                >
                                                    {pl && (
                                                        <PositionalPlayerCard
                                                            player={pl}
                                                            isRankedBoard={isRanked}
                                                            isChildBoard={isChildBoard}
                                                        />
                                                    )}
                                                </Box>
                                            </React.Fragment>
                                        )
                                    })}
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}
                </tbody>
            </Box>
        </Box>
    )
}

export default PositionalBoard
