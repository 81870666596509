import type { DropResult } from '@hello-pangea/dnd'
import type { UseMutateAsyncFunction } from '@tanstack/react-query'
import type { PrimaryPositionFilterOptions } from './PrimaryPositionFilter'
import type { BoardColumnsOnOff, BoardColumnsOpts, BoardSortColumn } from './BoardTableConfig'
import type { TableBodyProps } from '../Table/TableBody'
import type { PrintPlayer } from '../profile/PrintProfileButton'
import type { SelectListItem } from '@/lib/types/popoverAndDrawerTypes'
import type { JSONResponse } from '@/lib/api'
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import InventoryIcon from '@mui/icons-material/Inventory'
import Stack from '@mui/material/Stack'
import Chip from '@mui/material/Chip'
import AddIcon from '@mui/icons-material/Add'
import Switch from '@mui/material/Switch'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useSession } from 'next-auth/react'
import { ascending, descending, mean } from 'd3-array'
import Table from '../Table'
import PrintButton from '../PrintButton'
import DnDTableBody from '../Table/DnDTableBody'
import MultipleSelectSearch from '../search/MultipleSelectSearch'
import LOCDialog from '../dialog/LOCDialog'
import SkillDialog from '../dialog/SkillDialog'
import PrintProfileButton from '../profile/PrintProfileButton'
import ContractDetailsDialog from '../contracts/ContractDetailsDialog'
import TemporaryDrawer from '../TemporaryDrawer'
import { EntityName } from '../layout/header/EntityHeader'
import Popover from '../Popover'
import AutoRefreshSwitch from '../AutoRefreshSwitch'
import { mapItemsToTierNames } from '../../shared/utils/boards'
import FilterMenu from './FilterMenu'
import FilterRankListMenu from './FilterRankListMenu'
import PositionalBoard from './PositionalBoard'
import tableConfig, { defaultColumns, boardColToRowPropMap } from './BoardTableConfig'
import AddPlayerDialog from './AddPlayerDialog'
import AddTierDialog from './AddTierDialog'
import PrimaryPositionFilter from './PrimaryPositionFilter'
import EditBoardOptions from './EditBoardOptions'
import BoardModal from './BoardModal'
import AddTeamDialog from './AddTeamDialog'
import BoardPlayerNotesDialog from './BoardPlayerNotesDialog'
import BoardTeamNotesDialog from './BoardTeamNotesDialog'
import { getBoardTags } from './BoardList'
import BoardCompleteToggle from './BoardCompleteToggle'
import GradersMenu from './GradersMenu'
import BoardPlayerAvailabilityFilter from './BoardPlayerAvailabilityFilter'
import { useConstantsContext } from '@/lib/contexts/ConstantsContext'
import { useMeasurementPercentiles } from '@/lib/hooks/useMeasurements'
import { filterBoardColumns } from '@/lib/utils/boards'
import { mapSearchResultToTag, mapTagToSearchResult } from '@/lib/utils/search'
import useToastContext from '@/lib/hooks/useToastContext'
import {
    useBoardColumns,
    useBoardList,
    useBreakPoints,
    useLOCValues,
    useQueryPlayerContractsDetails,
    useSortToggle,
} from '@/lib/hooks'
import { useDeleteItem, useMarkRanked } from '@/lib/hooks/useBoard'
import PermissionFeature from '@/components/wrappers/PermissionFeature'

export type MovingAction = { id: null; index: null } | { id: string; index: number }
export const MAX_DISPLAY_HEADSHOTS = 200

const mapCreateUpdateBoard = (b: DTO.BoardData): DTO.CreateUpdateBoard => ({
    boardId: b.boardId,
    headline: b.displayName,
    columns: b.columns.map((d) => d.type),
    editors: b.editors,
    audience: b.audience,
    status: b.status,
    level: b.level,
    isRanked: b.isRanked,
    designations: b.designations || undefined,
    type: b.type,
    entityType: b.entityType,
    tag: b.tag ? { title: b.tag, inputValue: b.tag } : null,
    tagTitle: b.tag,
    isComplete: b.isComplete,
    parentId: b.parentId,
    graders: b.graders,
    version: b.version,
    season: b.season,
    author: b.author,
})

const sortByRankedSumAndRange = (a: DTO.BoardPlayer | DTO.BoardTeam, b: DTO.BoardPlayer | DTO.BoardTeam) => {
    if (a.rankRangeRatio && b.rankRangeRatio) {
        return (
            // Move players with no ranks to the bottom
            ascending(a.rankRangeRatio[0] === 0, b.rankRangeRatio[0] === 0) ||
            ascending(a.rankSum, b.rankSum) ||
            descending(a.rankRangeRatio[0], b.rankRangeRatio[0]) ||
            (a.rankRange && b.rankRange ? ascending(a.rankRange[0], b.rankRange[0]) : 0)
        )
    }
    return descending(a.rankSum, b.rankSum)
}

type BoardProps = {
    boardData: DTO.BoardData
    view: 'list' | 'position'
    onPlayerChange: UseMutateAsyncFunction<JSONResponse, Error, DTO.MovePlayerInput>
    onTeamChange: UseMutateAsyncFunction<JSONResponse, Error, DTO.MoveTeamInput>
    onTierChange: UseMutateAsyncFunction<JSONResponse, Error, DTO.MoveTierInput>
    handlePrint: () => void
    editMode: boolean
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>
    isLoading: boolean
    boardListItems?: SelectListItem[]
    autoRefresh?: boolean
    setAutoRefresh?: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Board({
    boardData,
    view,
    onPlayerChange,
    onTeamChange,
    onTierChange,
    handlePrint,
    editMode,
    setEditMode,
    isLoading,
    boardListItems,
    autoRefresh,
    setAutoRefresh,
}: BoardProps): JSX.Element {
    const [activeColumns, setActiveColumns] = useState(defaultColumns)
    const [activeRankColumns, setActiveRankColumns] = useState<string[]>([])
    const [openModal, setOpenModal] = useState<Partial<DTO.BoardModal> | null>(null)
    const [showMovingAction, setShowMovingAction] = useState<MovingAction>({ id: null, index: null })
    const [isAddPlayerDialogOpen, setIsAddPlayerDialogOpen] = useState(false)
    const [isAddTeamDialogOpen, setIsAddTeamDialogOpen] = useState(false)
    const [isAddTierDialogOpen, setIsAddTierDialogOpen] = useState(false)
    const [editPositionPlayerId, setEditPositionPlayerId] = useState<DTO.BoardPlayer['id'] | undefined>(undefined)
    const [selectedPositions, setSelectedPositions] = useState<PrimaryPositionFilterOptions>({})
    const [teamFilterTags, setTeamFilterTags] = useState<DTO.PostTag[]>([])
    const [openLOCDialog, setOpenLOCDialog] = useState<DTO.BoardPlayer['id'] | undefined>(undefined)
    const [openSkillDialog, setOpenSkillDialog] = useState<DTO.BoardPlayer['id'] | undefined>(undefined)
    const [openPlayerNotesDialog, setOpenPlayerNotesDialog] = useState<DTO.BoardPlayer | undefined>(undefined)
    const [openTeamNotesDialog, setOpenTeamNotesDialog] = useState<DTO.BoardTeam | undefined>(undefined)
    const [availablePlayersFilter, setAvailablePlayersFilter] = useState<Set<string>>(new Set())
    const [selectedGraders, setSelectedGraders] = useState(() => {
        const boardGradersMenu = new Map<number, boolean>()
        if (boardData.graders.length > 0) {
            boardData.graders.forEach((g) => {
                if (g.isComplete) boardGradersMenu.set(g.id, true)
                else boardGradersMenu.set(g.id, false)
            })
        }
        return boardGradersMenu
    })
    const [boardSortState, dispatchBoardSort] = useSortToggle<BoardColumnsOpts>()
    const { statsYear, draftYear } = useConstantsContext()
    const measurementLeagues =
        boardData.level === 'Amateur'
            ? (['College', 'G-League', 'OTE', 'International'] as Enum.League[])
            : (['NBA', 'G-League'] as Enum.League[])
    const { data: measurementPercentiles } = useMeasurementPercentiles({
        leagues: measurementLeagues,
        season: statsYear,
    })
    const { data: boardColumns } = useBoardColumns(boardData.entityType, boardData.level)
    const { data: boards } = useBoardList()
    const toastContext = useToastContext()
    const { data: locValues } = useLOCValues()
    const { data: session } = useSession()
    const { isMobile, isDesktop } = useBreakPoints()
    const [isContractDialogOpen, setIsContractDialogOpen] = useState<string | undefined>(undefined)
    const { data: contractsDetails, isLoading: isContractDetailsLoading } = useQueryPlayerContractsDetails({
        playerId: isContractDialogOpen,
        activeOnly: true,
    })

    const boardColumnsFiltered = filterBoardColumns(boardColumns, session, boardData.level)

    const boardRankColumnsFiltered = boards
        ?.filter((d) => d.level === boardData.level && d.isRanked === true && d.boardId !== boardData.boardId)
        .map((d) => ({
            title: d.headline,
            id: d.boardId,
        }))
        .sort((a, b) => a.title.localeCompare(b.title))

    const isActive = boardData.status === 'ACTIVE'
    const isEditor =
        (session?.roles.featurePermissions['custom-boards'] && !!boardData.isEditor) ||
        session?.roles.featurePermissions['board-management']

    const canPrintProfiles = session?.roles.featurePermissions['print-profiles'] && !isMobile

    const canEditBoardInfo =
        boardData.createdBy === session?.userId || session?.roles.featurePermissions['board-management']

    useEffect(() => {
        const allColumnsToOn: BoardColumnsOnOff = boardData.columns.reduce(
            (acc, c) => ({
                ...acc,
                [c.type]: true,
            }),
            defaultColumns
        )
        setActiveColumns(allColumnsToOn)
        // only set active columns when the board id changes
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boardData.boardId])

    useEffect(() => {
        if (isEditor && isActive && boardData.items.length === 0) setEditMode(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const boardItems = useMemo(() => {
        let items = boardData.items // eslint-disable-line prefer-destructuring
        // calculate average ranks and ranges from child boards
        if (boardData.graders.length) {
            // Determine the max rank for each board
            const maxRanks: { [boardId: string]: number } = {}
            items.forEach((group) => {
                if (group.type !== 'tier' && !!group.childRanks) {
                    group.childRanks.forEach((childRank) => {
                        const { boardId, rank, isRanked } = childRank
                        if (isRanked && (!(boardId in maxRanks) || rank > maxRanks[boardId])) {
                            maxRanks[boardId] = rank
                        }
                    })
                }
            })

            items = items.map((i) => {
                if (i.type === 'tier') return i

                const sortedChildRanks =
                    i.childRanks && selectedGraders.size
                        ? i.childRanks
                              .filter((r) => selectedGraders.get(r.graderId))
                              .sort((a, b) => ascending(a.rank, b.rank))
                        : []
                const childRanks = sortedChildRanks.filter((r) => r.isRanked)

                return {
                    ...i,
                    childRanks: sortedChildRanks,
                    avgRank: childRanks.length ? mean(childRanks, (r) => r.rank) : i.rank,
                    rankRange: childRanks.length
                        ? [childRanks[0].rank, childRanks[childRanks.length - 1].rank]
                        : undefined,
                    rankRangeRatio: [childRanks.length, sortedChildRanks.length],
                    rankSum: sortedChildRanks.length
                        ? sortedChildRanks.reduce(
                              (total, r) => (r.isRanked ? total + r.rank : total + (maxRanks[r.boardId] || 0) + 1),
                              0
                          )
                        : i.rank,
                }
            })
        }

        if (!editMode) {
            const teamUrlSlugs = new Set<string>(teamFilterTags.map((t) => t.entitySlug))
            items = items.filter(
                (i) =>
                    i.type !== 'player' ||
                    ((!teamUrlSlugs.size || teamUrlSlugs.has(i.player.teamUrlSlug as string)) &&
                        !availablePlayersFilter.has(i.player.playerId))
            )
            if (view !== 'position') {
                items = items.filter(
                    (i) =>
                        i.type !== 'player' ||
                        Object.keys(selectedPositions).length === 0 ||
                        selectedPositions[
                            i.player.playerPosition as DTO.PlayerPrimaryPosition | DTO.PlayerSecondaryPosition
                        ]
                )
            }

            const [boardTierGroups] = mapItemsToTierNames(items)
            const sortedItems: (DTO.BoardPlayer | DTO.BoardTier | DTO.BoardTeam)[][] = []
            let avgItemRank = 1
            boardTierGroups.forEach((v, tierName) => {
                // add tier back to its own group
                const tier = tierName && items.find((i) => i.type === 'tier' && i.name === tierName)
                if (tier) v.unshift(tier)

                const { column: sortedBoardColumn, order: boardColSortOrder } = boardSortState
                if (boardData.graders.length) {
                    v.sort((a, b) =>
                        a.type !== 'tier' && b.type !== 'tier' ? sortByRankedSumAndRange(a, b) : 0
                    ).forEach((i) => {
                        if (i.type !== 'tier') {
                            Object.assign(i, { rank: avgItemRank })
                            avgItemRank += 1
                        }
                    })
                } else if (sortedBoardColumn !== undefined) {
                    const indexes = v.map((_, i) => i)

                    // remove all players with no value in the sorted column
                    const filteredIndexes = indexes.filter((i) => {
                        const boardPlayer = v[i]
                        if (boardPlayer.type !== 'player') return false
                        const boardColumnProp =
                            boardColToRowPropMap.get(sortedBoardColumn) || (sortedBoardColumn as BoardSortColumn)
                        if (boardPlayer.player[boardColumnProp] && boardPlayer.player[boardColumnProp] !== '')
                            return false
                        return true
                    })
                    filteredIndexes.forEach((filteredIndex, curIndex) => {
                        const player = v[filteredIndex - curIndex]
                        v.splice(filteredIndex - curIndex, 1)
                        v.push(player)
                    })

                    v.sort((a, b) => {
                        if (a.type === 'player' && b.type === 'player') {
                            const boardColumnProp =
                                boardColToRowPropMap.get(sortedBoardColumn) || (sortedBoardColumn as BoardSortColumn)
                            const aProp = a.player[boardColumnProp]
                            const bProp = b.player[boardColumnProp]
                            if (aProp !== null && bProp !== null) {
                                if (boardColSortOrder === 'asc') return ascending(aProp, bProp)
                                if (boardColSortOrder === 'desc') return descending(aProp, bProp)
                            }
                        }
                        return 0
                    })
                }

                sortedItems.push(v)
            })

            items = sortedItems.flat()
        }
        return items
    }, [
        editMode,
        boardData.items,
        boardData.graders.length,
        teamFilterTags,
        view,
        selectedPositions,
        boardSortState,
        selectedGraders,
        availablePlayersFilter,
    ])
    const boardPlayers = useMemo<PrintPlayer[]>(
        () =>
            (boardItems.filter((d) => d.type === 'player') as DTO.BoardPlayer[]).map((i) => ({
                ...i.player,
                urlSlug: i.player.playerUrlSlug,
            })),
        [boardItems]
    )

    const { mutateAsync: handleRemove } = useDeleteItem(boardData.entityType, boardData, toastContext)

    const handlePositionChange = (
        e: React.MouseEvent,
        value: DTO.PlayerPrimaryPosition[] & DTO.PlayerSecondaryPosition[]
    ): void => {
        const selectedPosition = (e.target as HTMLButtonElement).value
        if (selectedPosition === 'ALL' || !value.length) {
            setSelectedPositions({})
        } else {
            const updatedPositions = value
                .filter(
                    (position: DTO.PlayerPrimaryPosition | DTO.PlayerSecondaryPosition) =>
                        position !== 'Guard' && position !== 'Wings' && position !== 'Bigs'
                )
                .reduce((acc: PrimaryPositionFilterOptions, p) => {
                    acc[p] = true
                    if (p === 'PG') acc.Guard = true
                    if (p === 'SG' || p === 'SF') acc.Wings = true
                    if (p === 'PF' || p === 'C') acc.Bigs = true
                    return acc
                }, {})

            setSelectedPositions(updatedPositions)
        }
    }

    const handleTeamChange = (value: DTO.SearchResult[]) => {
        const teamTags = value.map(mapSearchResultToTag)
        return setTeamFilterTags(teamTags)
    }

    const { mutateAsync: handleUnrankedClick } = useMarkRanked(boardData.entityType, boardData, toastContext)

    const boardTableConfig = useMemo(
        () =>
            tableConfig(
                boardData.boardId,
                boardColumnsFiltered.length ? boardColumnsFiltered : boardData.columns,
                activeColumns,
                boardRankColumnsFiltered || [],
                activeRankColumns,
                setOpenLOCDialog,
                setOpenSkillDialog,
                setIsContractDialogOpen,
                handleRemove,
                editMode,
                locValues,
                boardData.isRanked,
                editPositionPlayerId,
                setEditPositionPlayerId,
                !editMode || boardData.items.length < MAX_DISPLAY_HEADSHOTS,
                boardSortState,
                dispatchBoardSort,
                measurementPercentiles,
                setOpenPlayerNotesDialog,
                setOpenTeamNotesDialog,
                !!boardData.parentId,
                boardData.graders.length > 0,
                handleUnrankedClick
            ),
        [
            boardData.boardId,
            boardData.columns,
            boardData.isRanked,
            boardData.items.length,
            boardData.parentId,
            boardData.graders,
            boardColumnsFiltered,
            activeColumns,
            boardRankColumnsFiltered,
            activeRankColumns,
            handleRemove,
            editMode,
            locValues,
            editPositionPlayerId,
            boardSortState,
            dispatchBoardSort,
            measurementPercentiles,
            setOpenPlayerNotesDialog,
            setOpenTeamNotesDialog,
            handleUnrankedClick,
        ]
    )

    const TableBody = useCallback(
        (bodyProps: TableBodyProps<DTO.BoardPlayer | DTO.BoardTier | DTO.BoardTeam>) => {
            const handleDragEnd = (result: DropResult): void => {
                const { destination, source, draggableId } = result

                if (!destination) return
                if (destination.droppableId === source.droppableId && destination.index === source.index) return

                const { items, parentId } = boardData
                const movedItem = items.find((item) => item.id === draggableId)
                if (!movedItem) return

                if (parentId) {
                    let upperBound = items[0]?.type !== 'tier' ? -1 : 0
                    let lowerBound = items.length
                    items.forEach((item) => {
                        if (item.type === 'tier' && item.position >= upperBound && item.position < source.index) {
                            upperBound = item.position
                        }
                        if (item.type === 'tier' && item.position <= lowerBound && item.position > source.index) {
                            lowerBound = item.position
                        }
                    })
                    if (destination.index >= lowerBound || destination.index <= upperBound) {
                        toastContext?.addToast({
                            severity: 'warning',
                            message: 'Cannot move items between tiers',
                        })
                        return
                    }
                }

                items.splice(source.index, 1)
                items.splice(destination.index, 0, movedItem)

                setShowMovingAction({ id: null, index: null })

                const position = destination.index + (source.index < destination.index ? 1 : 0)

                let promise: Promise<JSONResponse>
                if (movedItem.type === 'tier') promise = onTierChange({ ...movedItem, position })
                else if (movedItem.type === 'team') promise = onTeamChange({ ...movedItem, position })
                else promise = onPlayerChange({ ...movedItem, position })

                void promise
                    .then(() => {
                        const capitalType = movedItem.type.charAt(0).toUpperCase() + movedItem.type.slice(1)
                        toastContext?.addToast({
                            severity: 'success',
                            message: `${capitalType} moved successfully`,
                            duration: 1000,
                        })
                    })
                    .catch((err: Error) => {
                        toastContext?.addToast({
                            severity: 'error',
                            message: err.message,
                        })
                    })
            }

            return (
                <DnDTableBody
                    handleDragEnd={handleDragEnd}
                    editMode={editMode}
                    boardData={boardData}
                    showMovingAction={showMovingAction}
                    setShowMovingAction={setShowMovingAction}
                    handleRemove={handleRemove}
                    {...bodyProps}
                />
            )
        },
        [
            editMode,
            boardData,
            showMovingAction,
            setShowMovingAction,
            handleRemove,
            onPlayerChange,
            onTeamChange,
            onTierChange,
            toastContext,
        ]
    )

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: { xs: 'flex-start', md: 'center' },
                    justifyContent: 'flex-start',
                    marginBottom: 1,
                    flexDirection: { xs: view === 'list' ? 'column' : 'row', md: 'row' },
                    gap: { xs: 1, md: 0 },
                }}
            >
                <Stack direction="row" alignItems="center">
                    {isMobile && (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <TemporaryDrawer
                                trigger={
                                    <EntityName name={boardData.displayName} isMobile={isMobile} entityType="board" />
                                }
                                listItems={boardListItems || []}
                                anchor="top"
                                iconList={false}
                            />
                        </Box>
                    )}
                    {!isMobile && (
                        <Popover
                            trigger={<EntityName name={boardData.displayName} isMobile={isMobile} entityType="board" />}
                            listItems={boardListItems || []}
                            iconList={false}
                        />
                    )}
                    {boardData.status === 'ARCHIVED' && (
                        <>
                            &nbsp;&nbsp;
                            <InventoryIcon sx={{ color: 'darkgrey' }} />
                        </>
                    )}
                    {canEditBoardInfo && (
                        <Box sx={{ displayPrint: 'none' }}>
                            <EditBoardOptions
                                board={mapCreateUpdateBoard(boardData)}
                                setModal={setOpenModal}
                                style={{ margin: '0px !important' }}
                            />
                        </Box>
                    )}
                </Stack>
                <Stack>
                    {view === 'list' && !editMode && !isLoading && boardData.entityType === 'PLAYER' && (
                        <Box
                            sx={{
                                marginLeft: { xs: 0, md: 2 },
                                displayPrint: 'none',
                                backgroundColor: 'common.white',
                            }}
                        >
                            <PrimaryPositionFilter
                                selectedPositions={selectedPositions}
                                handlePositionChange={handlePositionChange}
                            />
                        </Box>
                    )}
                </Stack>
                {!editMode && !isLoading && boardData.entityType === 'PLAYER' && (
                    <Box
                        sx={{
                            marginLeft: { xs: 0, md: 2 },
                            displayPrint: 'none',
                            width: { xs: '100%', md: '300px' },
                            marginRight: { xs: 0, md: 1 },
                            minWidth: { xs: '100%', md: '100px' },
                            backgroundColor: 'common.white',
                        }}
                    >
                        <MultipleSelectSearch
                            value={teamFilterTags.map(mapTagToSearchResult)}
                            onChange={handleTeamChange}
                            placeholder="Filter By Team"
                            searchType="team"
                        />
                    </Box>
                )}
                <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ marginLeft: { xs: 0, md: 'auto' }, displayPrint: 'none' }}
                >
                    {!isLoading && isDesktop && (
                        <PermissionFeature types={['auto-refresh']}>
                            <BoardPlayerAvailabilityFilter
                                onFilterChange={setAvailablePlayersFilter}
                                autoRefresh={autoRefresh}
                                season={draftYear}
                                leagueName={boardData.level === 'G-League' ? 'G-League' : 'NBA'}
                            />
                            {setAutoRefresh && (
                                <AutoRefreshSwitch checked={!!autoRefresh} setChecked={setAutoRefresh} />
                            )}
                        </PermissionFeature>
                    )}
                    {view === 'list' && (
                        <>
                            {editMode && !boardData.parentId && (
                                <Box display="flex" sx={{ gap: 1, marginRight: 2 }}>
                                    {boardData.entityType === 'PLAYER' && (
                                        <>
                                            <AddPlayerDialog
                                                isOpen={isAddPlayerDialogOpen}
                                                boardId={boardData.boardId}
                                                version={boardData.version}
                                                handleDialogClose={() => setIsAddPlayerDialogOpen(false)}
                                                tiers={boardItems.filter((d) => d.type === 'tier') as DTO.BoardTier[]}
                                            />
                                            <Chip
                                                icon={<AddIcon style={{ color: 'common.white' }} />}
                                                size="small"
                                                color="primary"
                                                label="Player"
                                                onClick={() => setIsAddPlayerDialogOpen(true)}
                                            />
                                        </>
                                    )}
                                    {boardData.entityType === 'TEAM' && (
                                        <>
                                            <AddTeamDialog
                                                isOpen={isAddTeamDialogOpen}
                                                boardId={boardData.boardId}
                                                version={boardData.version}
                                                handleDialogClose={() => setIsAddTeamDialogOpen(false)}
                                                tiers={boardItems.filter((d) => d.type === 'tier') as DTO.BoardTier[]}
                                            />
                                            <Chip
                                                icon={<AddIcon style={{ color: 'common.white' }} />}
                                                size="small"
                                                color="primary"
                                                label="Team"
                                                onClick={() => setIsAddTeamDialogOpen(true)}
                                            />
                                        </>
                                    )}
                                    <AddTierDialog
                                        isOpen={isAddTierDialogOpen}
                                        boardId={boardData.boardId}
                                        version={boardData.version}
                                        handleDialogClose={() => setIsAddTierDialogOpen(false)}
                                    />
                                    <Chip
                                        icon={<AddIcon style={{ color: 'common.white' }} />}
                                        size="small"
                                        color="primary"
                                        label="Tier"
                                        onClick={() => setIsAddTierDialogOpen(true)}
                                    />
                                </Box>
                            )}
                            {isEditor && isActive && !isLoading && (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            size="small"
                                            checked={editMode}
                                            onChange={(e) => {
                                                setSelectedPositions({})
                                                setEditMode(e.target.checked)
                                            }}
                                        />
                                    }
                                    label="Edit Mode"
                                />
                            )}

                            {!!boardColumnsFiltered.length && !isLoading && (
                                <FilterMenu
                                    columns={boardColumnsFiltered}
                                    activeColumns={activeColumns}
                                    setActiveColumns={setActiveColumns}
                                />
                            )}

                            {!!boardRankColumnsFiltered && !isLoading && boardData.entityType === 'PLAYER' && (
                                <FilterRankListMenu
                                    columns={boardRankColumnsFiltered}
                                    activeColumns={activeRankColumns}
                                    setActiveColumns={setActiveRankColumns}
                                />
                            )}
                        </>
                    )}
                    {!isLoading && <PrintButton handlePrint={handlePrint} />}
                    {!isLoading && canPrintProfiles && boardData.entityType === 'PLAYER' && (
                        <PrintProfileButton players={boardPlayers} level={boardData.level} />
                    )}
                    {boardData.graders.length > 0 && (
                        <GradersMenu
                            allGraders={boardData.graders}
                            setSelectedGraders={setSelectedGraders}
                            selectedGraders={selectedGraders}
                        />
                    )}
                </Stack>
            </Box>
            {isEditor && isActive && !isLoading && !!boardData.parentId && (
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: { xs: 'flex-start', md: 'flex-end' },
                        marginBottom: 1,
                    }}
                >
                    <BoardCompleteToggle
                        boardId={boardData.boardId}
                        isComplete={boardData.isComplete}
                        version={boardData.version}
                    />
                </Box>
            )}
            {view === 'list' && (
                <Paper
                    variant="outlined"
                    sx={{
                        '@media print': {
                            border: 0,
                            '.MuiTableContainer-root': { maxHeight: 'none' },
                            '@page': {
                                size: '432mm 328mm',
                                marginX: '28px',
                                marginY: '20px',
                            },
                        },
                    }}
                >
                    <Table<DTO.BoardPlayer | DTO.BoardTier | DTO.BoardTeam>
                        config={boardTableConfig}
                        rows={boardItems}
                        getRowKey={(r) => r.id}
                        isLoading={isLoading}
                        body={TableBody}
                        height={{ xs: window.innerHeight - 320, md: window.innerHeight - 180 }}
                        stickyHeader
                    />
                </Paper>
            )}
            {view === 'position' && (
                <PositionalBoard items={boardItems} isRanked={boardData.isRanked} isChildBoard={!!boardData.parentId} />
            )}
            <BoardModal
                level={boardData.level}
                open={!!openModal}
                setOpen={setOpenModal}
                boardId={boardData.boardId}
                initialVals={openModal}
                redirect={false}
                tags={getBoardTags(boards)}
            />
            {!!openLOCDialog && boardData.entityType === 'PLAYER' && (
                <LOCDialog
                    open={!!openLOCDialog}
                    setOpen={setOpenLOCDialog}
                    name={(boardItems.find((bp) => bp.id === openLOCDialog) as DTO.BoardPlayer).name}
                    playerId={openLOCDialog}
                    isNBAPlayer={
                        !!(boardItems.find((bp) => bp.id === openLOCDialog) as DTO.BoardPlayer).player.isNBAPlayer
                    }
                    boardId={boardData.boardId}
                />
            )}
            {!!openSkillDialog && boardData.entityType === 'PLAYER' && (
                <SkillDialog
                    open={!!openSkillDialog}
                    setOpen={setOpenSkillDialog}
                    name={(boardItems.find((bp) => bp.id === openSkillDialog) as DTO.BoardPlayer).name}
                    playerId={openSkillDialog}
                    boardLevel={boardData.level}
                />
            )}
            <ContractDetailsDialog
                contract={contractsDetails?.[0]}
                isLoading={isContractDetailsLoading}
                open={!!isContractDialogOpen}
                setOpen={setIsContractDialogOpen}
            />
            {openPlayerNotesDialog && (
                <BoardPlayerNotesDialog
                    open={!!openPlayerNotesDialog}
                    initialValues={openPlayerNotesDialog}
                    setOpen={setOpenPlayerNotesDialog}
                    boardId={boardData.boardId}
                />
            )}
            {openTeamNotesDialog && (
                <BoardTeamNotesDialog
                    open={!!openTeamNotesDialog}
                    initialValues={openTeamNotesDialog}
                    setOpen={setOpenTeamNotesDialog}
                    boardId={boardData.boardId}
                />
            )}
        </>
    )
}
